import axios from "axios"
import { DateTime } from "luxon"
import { decodeQueryParam, Logger } from "packs/common"
// import qs from "qs"
// import isMobile from "ismobilejs"
import Appointment from "packs/models/Appointment"
import Util from "./Util"
import RoomMember from "packs/models/RoomMember"
import FormField from "packs/models/FormField"
// declare var ics: any
// const ics = require("ics")
// const { writeFileSync } = require("fs")

//  便利系を記載.
export default class FormFieldCreator {
    static readonly selectDefaultYears: number[] = Array(100)
        .fill(0)
        .map((_, i) => DateTime.local().year + 1 - i)

    static readonly selectMonths: number[] = Array(12)
        .fill(0)
        .map((_, i) => i + 1)

    static selectYears(startYear: number, endYear: number): number[] {
        const years: number[] = []
        for (let year = startYear; year <= endYear; year++) {
            years.push(year)
        }
        return years
    }

    static fieldTypeArray(): any[] {
        return [
            { field_type: `input`, name: `1行記述`, icon: `/assets/icons/field_one` },
            { field_type: `textarea`, name: `複数行記述`, icon: `/assets/icons/field_multi` },
            { field_type: `radio`, name: `ラジオボタン (単一選択)`, icon: `/assets/icons/field_radio` },
            { field_type: `select`, name: `プルダウン`, icon: `/assets/icons/field_dropdown` },
            { field_type: `checkbox`, name: `チェックボックス (複数選択)`, icon: `/assets/icons/field_checkbox` },
            { field_type: `birthdate`, name: `年月日`, icon: `/assets/icons/field_birthdate` },
            { field_type: `prefectures`, name: `都道府県`, icon: `/assets/icons/japan` },
            { field_type: `address`, name: `住所`, icon: `/assets/icons/postalcode` },

            { field_type: `upload_file`, name: `ファイルアップロード`, icon: `/assets/icons/upload` },
            { field_type: `radio_image`, name: `画像選択 (単一選択)`, icon: `/assets/icons/select_images` },
            { field_type: `checkbox_image`, name: `画像選択 (複数選択)`, icon: `/assets/icons/select_images` },
        ]
    }

    static fieldTypeSpecialInputArray(needNameAndCompany = false): any[] {
        let arr = []
        if (needNameAndCompany) {
            arr = [
                ...arr,
                { field_type: `attendee1_name`, name: `氏名`, icon: `/assets/icons/field_one` },
                { field_type: `attendee1_email`, name: `メールアドレス`, icon: `/assets/icons/field_one` },
            ]
        }
        arr = [
            ...arr,
            { field_type: `attendee1_company_name`, name: `会社名`, icon: `/assets/icons/field_one` },
            { field_type: `attendee1_cc_emails`, name: `CCメールアドレス`, icon: `/assets/icons/field_one` },
            { field_type: `attendee1_tel`, name: `電話番号`, icon: `/assets/icons/call` },
        ]
        return arr
    }

    static fieldTypeSpecialParamKeyArray(needNameAndCompany = false) {
        return this.fieldTypeSpecialInputArray(needNameAndCompany).map(dic => dic.field_type)
    }

    // 入力なし項目
    static fieldTypeNoneInputArray(): any[] {
        return [
            { field_type: `head1`, name: `大見出し`, icon: `/assets/icons/field_text_head` },
            { field_type: `head2`, name: `小見出し`, icon: `/assets/icons/field_text_head` },
            { field_type: `image1`, name: `画像`, icon: `/assets/icons/select_images` },
        ]
    }

    static resetFieldValues(fields: FormField[]) {
        if (Util.isBlank(fields)) return
        let _fs = [...fields]
        let fs = []
        for (let _f of _fs) {
            _f.value_attr = null
            _f.selected_values = []
            fs.push(_f)
        }
        return fs
    }

    /**
     * 選択したフィールド名から、作成します.
     */
    static getFieldFromFieldType(_typeDic: any) {
        let _f = FormField.createDefault(0)
        _f.field_type = _typeDic.field_type
        _f.field_name = `新規${_typeDic.name}`

        if (
            this.fieldTypeSpecialInputArray(true)
                .map(t => t.field_type)
                .includes(_typeDic.field_type)
        ) {
            // 特殊フィールドです.
            if (_typeDic.field_type == `attendee1_company_name`) {
                _f = FormField.createCompanyField(0)
            } else if (_typeDic.field_type == `attendee1_cc_emails`) {
                _f = FormField.createCCEmailsField(0)
            } else if (_typeDic.field_type == `attendee1_tel`) {
                _f = FormField.createTelField(0)
            } else if (_typeDic.field_type == `attendee1_name`) {
                _f = FormField.createNameField(0)
            } else if (_typeDic.field_type == `attendee1_email`) {
                _f = FormField.createEmailField(0)
            }
        }
        if (
            FormField.selectableTypes.includes(_typeDic.field_type) ||
            FormField.selectableWithImageTypes.includes(_typeDic.field_type)
        ) {
            _f.selectable_values = [
                { value: `選択肢1`, image_url: `` },
                { value: `選択肢2`, image_url: `` },
                { value: `選択肢3`, image_url: `` },
            ]
            _f.default_selected_values = []
        } else if (_typeDic.field_type == `prefectures`) {
            _f = FormFieldCreator.prefectures()
            _f.field_name = `新規${_typeDic.name}`
        }

        return _f
    }

    static birthdateField() {
        let ff = FormField.createDefault()
    }

    static createField(
        indexNum = 0,
        fieldType: string,
        name: string,
        description: string,
        placeholder: string,
        isRequired: boolean = false
    ) {
        let _f = FormField.createDefault(indexNum)
        _f.canDelete = true
        _f.field_type = fieldType
        _f.field_name = name
        _f.description = description
        _f.placeholder_attr = placeholder
        _f.is_required = isRequired
        return _f
    }

    /**
     * 電話番号
     * @param indexNum
     */
    static phone(indexNum = 0) {
        return this.createField(indexNum, `input`, `電話番号`, `ハイフンなしで記入してください。`, `09025252525`, false)
    }

    static content(indexNum = 0, name = `お問い合わせ内容`) {
        return this.createField(indexNum, `textarea`, name, ``, ``, false)
    }

    static addressGroup() {
        let postcode = this.createField(0, `input`, `郵便番号`, ``, `1002222`, false)
        let address = this.createField(0, `input`, `住所`, ``, `東京都千代田区飯田橋`, false)
        let mansionName = this.createField(0, `input`, `ビル・マンション名など`, ``, `マンション`, false)
        return [postcode, address, mansionName]
    }

    static serviceName(): FormField {
        return this.createField(0, `input`, `ご利用されたサービス名`, ``, ``, false)
    }

    static serviceStart(): FormField {
        return this.createField(0, `birthdate`, `サービスご利用日`, ``, ``, false)
    }

    static servicePic(): FormField {
        return this.createField(0, `input`, `サービス担当者`, ``, `湧田 洋介`, false)
    }

    static gender(): FormField {
        let _f = FormField.createDefault(0)
        _f.field_type = `radio`
        _f.field_name = `性別`
        _f.selectable_values = [
            { value: `男性`, image_url: null },
            { value: `女性`, image_url: null },
        ]
        _f.default_selected_values = []
        return _f
    }

    static birthday(name = `生年月日`): FormField {
        let _f = FormField.createDefault(0)
        _f.field_type = `birthdate`
        _f.field_name = name
        return _f
    }

    static readonly getPrefeturesArray = [
        "北海道",
        "青森県",
        "岩手県",
        "宮城県",
        "秋田県",
        "山形県",
        "福島県",
        "茨城県",
        "栃木県",
        "群馬県",
        "埼玉県",
        "千葉県",
        "東京都",
        "神奈川県",
        "新潟県",
        "富山県",
        "石川県",
        "福井県",
        "山梨県",
        "長野県",
        "岐阜県",
        "静岡県",
        "愛知県",
        "三重県",
        "滋賀県",
        "京都府",
        "大阪府",
        "兵庫県",
        "奈良県",
        "和歌山県",
        "鳥取県",
        "島根県",
        "岡山県",
        "広島県",
        "山口県",
        "徳島県",
        "香川県",
        "愛媛県",
        "高知県",
        "福岡県",
        "佐賀県",
        "長崎県",
        "熊本県",
        "大分県",
        "宮崎県",
        "鹿児島県",
        "沖縄県",
    ]

    static prefectures(): FormField {
        let prefs = this.getPrefeturesArray
        let prefField = this.createField(0, `select`, `都道府県`, ``, ``, false)
        prefField.selectable_values = prefs.map(pr => {
            return { value: pr, image_url: null }
        })
        return prefField
    }

    static postalcodeGroup(): FormField[] {
        let postalcode = this.createField(0, `input`, `郵便番号`, ``, ``, false)
        let prefs = this.prefectures()
        let city = this.createField(0, `input`, `市区町村`, ``, ``, false)
        let address1 = this.createField(0, `input`, `番地`, ``, ``, false)
        let address2 = this.createField(0, `input`, `マンションなど`, ``, ``, false)
        return [postalcode, prefs, city, address1, address2]
    }

    static univGroup(): FormField[] {
        let univ = this.createField(0, `input`, `大学名`, ``, ``, false)
        let faculty = this.createField(0, `input`, `学部名`, ``, ``, false)
        let department = this.createField(0, `input`, `学科・コース名`, ``, ``, false)
        let academicBackground = this.createField(0, `select`, `最終学歴`, ``, ``, false)
        let selects = [
            `高等学校 中退`,
            `高等学校 卒業`,
            `専門学校・短期大学 中退`,
            `専門学校・短期大学 卒業`,
            `大学 中退`,
            `大学 卒業`,
            `大学院 中退`,
            `大学院 卒業`,
        ]
        academicBackground.selectable_values = selects.map(sl => {
            return { value: sl, image_url: null }
        })
        academicBackground.default_selected_values = []
        let grad = this.birthday(`卒業年月日`)
        let going = this.content(0, `現在の就職活動状況（これまでの経緯）についてお聞かせください`)
        let motivation = this.content(0, `志望動機をお聞かせください。`)
        let pr = this.content(0, `自己PRをお願いします。`)
        let univGoing = this.content(0, `学生時代に力を入れたことについてお聞かせください。`)
        let thesis = this.content(0, `ゼミ、卒論についてお聞かせください。`)
        let nextTodo = this.content(0, `入社後、特にやりたいことがあれば教えてください。`)
        return [univ, faculty, department, academicBackground, grad, going, motivation, pr, univGoing, thesis, nextTodo]
    }

    static companyInfoGroup(): FormField[] {
        let branch = this.createField(0, `input`, `支社・支店名`, ``, ``, false)
        let department = this.createField(0, `input`, `部門`, ``, ``, false)
        let division = this.createField(0, `input`, `課`, ``, ``, false)
        return [branch, department, division]
    }

    static question(): FormField {
        let question = this.content(0, `当日お聞きになりたいことがあれば事前に教えて下さい。`)
        return question
    }

    static remarks(): FormField {
        let question = this.content(0, `備考`)
        return question
    }

    static satisfaction(): FormField {
        let question = this.createField(0, `radio`, `満足度`, ``, ``, true)
        question.description = ``
        question.selectable_values = [`不満`, `少し不満`, `まあまあ`, `満足`, `大変満足`].map(pr => {
            return { value: pr, image_url: null }
        })
        return question
    }

    static satisfactionReason(): FormField {
        let question = this.content(0, `満足度の理由を教えて下さい`)
        question.description = `上記でそう答えられた理由を詳細に教えてください。`
        return question
    }

    static useRepeat(): FormField {
        let question = this.createField(0, `radio`, `またお使いになられたいですか？`, ``, ``, true)
        question.selectable_values = [`はい`, `いいえ`].map(pr => {
            return { value: pr, image_url: null }
        })
        return question
    }

    static request(): FormField {
        let question = this.content(0, `ご意見・ご要望などお聞かせください！`)
        return question
    }

    static nextSchedule(): FormField {
        let question = this.createField(0, `radio`, `またご相談など調整をご希望ですか？`, ``, ``, true)
        question.selectable_values = [`いいえ、今は結構です`, `はい、お願いします`].map(pr => {
            return { value: pr, image_url: null }
        })
        return question
    }

    static wantOnlineMeeting(): FormField {
        let _f = FormField.createDefault(0)
        _f.field_type = `radio`
        _f.field_name = `オンラインで相談を希望`
        _f.selectable_values = [
            { value: `希望する`, image_url: null },
            { value: `希望しない`, image_url: null },
        ]
        _f.default_selected_values = []
        _f.is_required = true
        return _f
    }

    static wantOnlineMeetingRule(): FormField[] {
        let _f = this.wantOnlineMeeting()
        _f.selected_values = [`希望する`]
        return [_f]
    }

    static wantNextScheduleRule(): FormField[] {
        let _f = this.nextSchedule()
        _f.selected_values = [`はい、お願いします`]
        return [_f]
    }

    static setValueFromAdditionalParams(_fields: FormField[], additionalParams: any) {
        Logger(`setValueFromAdditionalParams additionalParams: ${Util.output(additionalParams)}`)
        if (!_fields) return []

        for (let _f of _fields) {
            if (Util.isPresent(_f.value_attr)) continue

            if (_f.param_key == `attendee1_name` && Util.isPresent(additionalParams[`name`])) {
                _f.value_attr = decodeQueryParam(additionalParams[`name`])
            } else if (_f.param_key == `attendee1_email` && Util.isPresent(additionalParams[`email`])) {
                _f.value_attr = decodeQueryParam(additionalParams[`email`])
            } else if (_f.param_key == `attendee1_company_name` && Util.isPresent(additionalParams[`company_name`])) {
                _f.value_attr = decodeQueryParam(additionalParams[`company_name`])
            } else if (_f.param_key == `attendee1_tel` && Util.isPresent(additionalParams[`phone_number`])) {
                _f.value_attr = decodeQueryParam(additionalParams[`phone_number`])
            } else {
                if (Util.isPresent(additionalParams[_f.param_key])) {
                    if (FormField.selectableTypes.includes(_f.field_type)) {
                        let vals = decodeQueryParam(additionalParams[_f.param_key])
                        _f.selected_values = vals.split(`,`) || []
                    } else {
                        _f.value_attr = decodeQueryParam(additionalParams[_f.param_key])
                    }
                }
            }
            let hff = additionalParams.hide_filled_fields
            if ((Util.isPresent(_f.value_attr) || Util.isPresent(_f.selected_values)) && Util.isPresent(hff) && hff == "true") {
                _f.hideField = true
            }
        }
        return _fields
    }

    static createFormFieldsFromArray(ffs: FormField[], ffsArray: any[]) {
        if (!ffs) ffs = []
        // let ffs = []
        if (Util.isPresent(ffsArray)) {
            for (let _ffname of ffsArray) {
                let _ff = null

                if (_ffname == `phone`) {
                    _ff = FormFieldCreator.phone()
                } else if (_ffname == `content`) {
                    _ff = FormFieldCreator.content()
                } else if (_ffname == `addressGroup`) {
                    _ff = FormFieldCreator.addressGroup()
                } else if (_ffname == `serviceName`) {
                    _ff = FormFieldCreator.serviceName()
                } else if (_ffname == `serviceStart`) {
                    _ff = FormFieldCreator.serviceStart()
                } else if (_ffname == `servicePic`) {
                    _ff = FormFieldCreator.servicePic()
                } else if (_ffname == `gender`) {
                    _ff = FormFieldCreator.gender()
                } else if (_ffname == `birthday`) {
                    _ff = FormFieldCreator.birthday()
                } else if (_ffname == `univGroup`) {
                    _ff = FormFieldCreator.univGroup()
                } else if (_ffname == `companyInfoGroup`) {
                    _ff = FormFieldCreator.companyInfoGroup()
                } else if (_ffname == `question`) {
                    _ff = FormFieldCreator.question()
                } else if (_ffname == `wantOnlineMeeting`) {
                    _ff = FormFieldCreator.wantOnlineMeeting()
                } else if (_ffname == `remarks`) {
                    _ff = FormFieldCreator.remarks()
                } else if (_ffname == `satisfaction`) {
                    _ff = FormFieldCreator.satisfaction()
                } else if (_ffname == `satisfactionReason`) {
                    _ff = FormFieldCreator.satisfactionReason()
                } else if (_ffname == `useRepeat`) {
                    _ff = FormFieldCreator.useRepeat()
                } else if (_ffname == `request`) {
                    _ff = FormFieldCreator.request()
                } else if (_ffname == `nextSchedule`) {
                    _ff = FormFieldCreator.nextSchedule()
                }
                if (Util.isPresent(_ff)) {
                    if (Array.isArray(_ff)) {
                        Array.prototype.push.apply(ffs, _ff)
                    } else {
                        ffs.push(_ff)
                    }
                }
            }
        }
        ffs = FormField.updateIndexNum(ffs)
        return ffs
    }
}
